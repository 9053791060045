import Router from './routes';
import React from "react";
import {StatusBar, Style} from "@capacitor/status-bar";
import {Keyboard} from "@capacitor/keyboard";
import {App as CapacitorApp} from "@capacitor/app";
import {createGlobalStyle} from 'styled-components';
import {KeyboardResize} from "@capacitor/keyboard/dist/esm/definitions";
import { Device } from '@capacitor/device';
import _ from 'lodash';

const OverrideMobileAppGlobalStyle = createGlobalStyle`
  .x-hellosign-embedded {
    margin-top: env(safe-area-inset-top);
    height: calc(100% - env(safe-area-inset-top));
  }
  .pac-container {
    position: relative !important;
    left: 0 !important;
    top: 0 !important;
  }
`;

function App() {
    const isMobileApp = process.env.REACT_APP_IS_MOBILE_APP === "true"
    const isProd = process.env.NODE_ENV === 'production'

    React.useEffect(() => {
        if (!isMobileApp|| !isProd) return
        const fetchData =  async () => {
            const deviceInfo = await Device.getInfo();
            const isIos = deviceInfo?.platform === "ios"
            if (isIos) {
                Keyboard.setResizeMode({mode: KeyboardResize.None})
                Keyboard.addListener("keyboardWillShow", info => {
                    const accountCardElement = document.getElementById("accountCardElement")
                    if (accountCardElement) accountCardElement.style.paddingBottom = `${info.keyboardHeight}px`
                    const muiDialogElement = _.first(document.getElementsByClassName("MuiDialog-container"))
                    if (muiDialogElement) muiDialogElement.style.paddingBottom = `${info.keyboardHeight}px`
                    const elementFocused = document.querySelector(':focus');
                    if (elementFocused) elementFocused.scrollIntoView({ block : "center" })
                    const loginContainer = document.getElementById("loginContainer")
                    if (loginContainer) loginContainer.style.marginBottom = "150px"
                })
                Keyboard.addListener("keyboardWillHide", () => {
                    const accountCardElement = document.getElementById("accountCardElement")
                    if (accountCardElement) accountCardElement.style.paddingBottom = '20px'
                    const muiDialogElement = _.first(document.getElementsByClassName("MuiDialog-container"))
                    if (muiDialogElement) muiDialogElement.style.paddingBottom = 0
                    const loginContainer = document.getElementById("loginContainer")
                    if (loginContainer) loginContainer.style.marginBottom = 0
                })
            } else { //Android
                StatusBar.setBackgroundColor({color: '#f5f5f5'});
                CapacitorApp.addListener('backButton', ({canGoBack}) => {
                    if (!canGoBack) CapacitorApp.exitApp();
                    else window.history.back();
                });
            }
            // Common
            StatusBar.setStyle({style: Style.Light});
        }
        fetchData()
    }, [isMobileApp, isProd]);

    return (
        <>
            {isMobileApp ? <OverrideMobileAppGlobalStyle/> : null}
            <Router/>
        </>
    );
}

export default App;
