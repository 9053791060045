import React from 'react';
import { useTranslation } from 'react-i18next';
import {makeStyles} from "@mui/styles";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {Button, Grid} from "@mui/material";
import cause_1 from "../../../static/refund/cause_1.svg"
import cause_2 from "../../../static/refund/cause_2.svg"
import cause_3 from "../../../static/refund/cause_3.svg"
import cause_4 from "../../../static/refund/cause_4.svg"
import cause_5 from "../../../static/refund/cause_5.svg"
import cause_6 from "../../../static/refund/cause_6.svg"
import cause_7 from "../../../static/refund/cause_7.svg"
import cause_8 from "../../../static/refund/cause_8.svg"
import cause_9 from "../../../static/refund/cause_9.svg"
import Stack from "@mui/material/Stack";

const useStyles = makeStyles((theme)=>({
    box: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: "10px",
        marginBottom: "10px",
        '&:hover':{
            backgroundColor: '#C0D8E2'
        }
    },
    img: {
        width: 120,
        height: 120,
        marginBottom: 10,
        [theme.breakpoints.down("xl")]: {
            width: 100,
            height: 100,
        },
        [theme.breakpoints.down("lg")]: {
            width: 80,
            height: 80,
        },
        [theme.breakpoints.down("sm")]: {
            width: 65,
            height: 65,
        },
    },
    typo: {
        fontSize: '18px',
        width: 160,
        height: "24px",
        [theme.breakpoints.down("sm")]: {
            fontSize: '16px',
            width: 130,
        },
    },
}));


export default function Step1(props) {
    const { t } = useTranslation('account', { keyPrefix: 'getrefund.step1' });
    const causes = [
        { value: 'pharmacy', label: t('label_pharmacy'), logo: cause_1 },
        { value: 'analysis', label: t('label_analysis'), logo: cause_2 },
        { value: 'equipment', label: t('label_equipment'), logo: cause_3 },
        { value: 'optical', label: t('label_optical'), logo: cause_4 },
        { value: 'dental', label: t('label_dental'), logo: cause_5 },
        { value: 'hospitalization', label: t('label_hospital'), logo: cause_6 },
        { value: 'consultation', label: t('label_consult'), logo: cause_7 },
        { value: 'hearing', label: t('label_hearing'), logo: cause_8 },
        { value: 'other', label: t('label_other'), logo: cause_9 },
    ]
    
    const { handleNext, handleBack, hasStep0 } = props
    const classes = useStyles();

    return (
        <section style={{ height: '100%', display: 'flex', flexDirection: 'column'}}>
            <Box style={{ width: '100%', height:'100%', display: "flex", flexDirection: "column"}}>
                <Typography variant={"h2"} display={'flex'} justifyContent={'center'}>{props.isQuote ? t('title_quote') : t('title_refund')}</Typography>
                <section style={{ height: '90%', display: 'flex' }}>
                    <Grid container
                          direction="row"
                          justifyContent="center"
                          alignItems="center">
                        {causes.map(item =>
                            <Grid item xs={4}
                                  key={item.value}
                                  style={{ display: 'flex', justifyContent: 'center'}}>
                                <Button className={classes.box}
                                        onClick={() => { handleNext({typeCare: item.value}) }}
                                        style={{ backgroundColor: props.isActive ? '#C0D8E2' : '' }}>
                                    <img src={item.logo}
                                         className={classes.img}
                                         alt={''} />
                                    <Typography className={classes.typo}>{item.label}</Typography>
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                </section>
            </Box>
            {hasStep0 ?
                <Stack sx={{ display: 'flex', justifyContent: 'center', marginTop: {xs: '10px', sm: '20px'}, paddingBottom: '20px' }}>
                    <Button variant='contained' style={{fontSize: '18px'}} color="button" onClick={handleBack}>
                        {t('prev')}
                    </Button>
                </Stack> :
                null}
        </section>
    );
}