import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import subscriptionReducer from '../components/subscription/form/subscriptionSlice';
import accountReducer from '../components/accountSlice';
import { maintenanceApi } from '../components/maintenance/maintenanceSlice';

const persistConfig = {
  key: 'root',
  storage,
  blacklist: [maintenanceApi.reducerPath]
};

const reducers = combineReducers({
  subscription: subscriptionReducer,
  account: accountReducer,
  [maintenanceApi.reducerPath]:maintenanceApi.reducer
});

const persistedReducer = persistReducer(persistConfig, reducers);

export default configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(maintenanceApi.middleware)
});