import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

export const sectionPadding = {
  pl: { xs: '10px', ss: '18px' },
  pr: { xs: '10px', ss: '18px' },
  pt: { xs: '70px', md: '100px' },
  pb: { xs: '70px', md: '100px' },
};

export const limitSectionWidth = {
  maxWidth: { xs: '800px', md: 'unset' },
};

export const StackItem = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: 'center',
  boxShadow: 'none',
}));

export const typographyTitle = {
  fontSize: { xs: '30px !important', md: '44px !important' },
  fontWeight: '600 !important',
  lineHeight: '1.2 !important',
  fontFamily: '"sohne-var","Helvetica Neue","Arial",sans-serif',
  color: '#32325d !important',
};

export const typographyContent = {
  fontSize: '18px !important',
  fontWeight: '400 !important',
  lineHeight: '1.5 !important',
  fontFamily: '"sohne-var","Helvetica Neue","Arial",sans-serif',
};

const styles = {
  sectionPadding,
  limitSectionWidth,
  StackItem,
  typographyTitle,
  typographyContent,
};
export default styles;
