import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { subscriptionForm } from '../../../utils/axiosRequests';

const initialState = {
  status: 'idle',
  id: null,
  firstName: '',
  lastName: '',
  gender: '',
  birthday: null,
  organism: '',
  address: {},
  formattedAddress: '',
  email: '',
  phone: '',
  secu: '',
};

export const formSubscription = createAsyncThunk(
  'subscription/form',
  async ({ data, requestToken }) => {
    const response = await subscriptionForm(requestToken, {
      ...data,
    });
    return response.data;
  },
);

export const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {
    reset: () => initialState,
    selectFirstName: (state, action) => {
      state.firstName = action.payload;
    },
    selectLastName: (state, action) => {
      state.lastName = action.payload;
    },
    selectGender: (state, action) => {
      state.gender = action.payload;
    },
    selectBirthday: (state, action) => {
      state.birthday = action.payload;
    },
    selectAddress: (state, action) => {
      state.address = action.payload;
    },
    selectEmail: (state, action) => {
      state.email = action.payload;
    },
    selectPhone: (state, action) => {
      state.phone = action.payload;
    },
    selectSecu: (state, action) => {
      state.secu = action.payload;
    },
    selectFormattedAddress: (state, action) => {
      state.formattedAddress = action.payload;
    },
    selectOrganism: (state, action) => {
      state.organism = action.payload;
    },
    selectContractStartDate: (state, action) => {
      state.contractStartDate = action.payload;
    },
    selectContractType: (state, action) => {
      state.contractType = action.payload;
    },
    selectContractTime: (state, action) => {
      state.contractTime = action.payload;
    },
  },
  extraReducers: {
    [formSubscription.pending]: (state) => {
      state.status = 'loading';
    },
    [formSubscription.fulfilled]: (state, { payload }) => {
      state.status = 'succeeded';
      state.id = payload.id;
    },
    [formSubscription.rejected]: (state, { payload }) => {
      state.status = 'failed';
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  reset,
  selectFirstName,
  selectLastName,
  selectGender,
  selectAddress,
  selectEmail,
  selectBirthday,
  selectPhone,
  selectSecu,
  selectFormattedAddress,
  selectOrganism,
  selectContractStartDate,
  selectContractType,
  selectContractTime,
} = subscriptionSlice.actions;

export default subscriptionSlice.reducer;
