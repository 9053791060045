import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CustomCarousel from '../../molecules/Carousel';
import { useSelector } from 'react-redux';
import { Button } from '@mui/material';
import human_logo_1 from '../../../static/refund/human_logo_1.svg';
import human_logo_2 from '../../../static/refund/human_logo_2.svg';
import human_logo_3 from '../../../static/refund/human_logo_3.svg';

const useStyles = makeStyles((theme) => ({
  box: {
    display: 'flex',
    flexDirection: 'column',
    '&:hover': {
      backgroundColor: '#C0D8E2',
    },
  },
  img: {
    width: 120,
    height: 120,
    [theme.breakpoints.down('lg')]: {
      width: 100,
      height: 100,
    },
    marginBottom: 10,
  },
  typo: {
    backgroundColor: '#52d0c690',
    fontSize: '18px',
    width: 150,
    overflow: 'auto',
  },
}));

export default function Step0(props) {
  const { t } = useTranslation('account', {
    keyPrefix: 'getrefund.step0',
  });
  const { handleNext, rightholders, rightholder, isQuote } = props;
  const {
    lastName,
    firstName,
    id: userId,
  } = useSelector((state) => state.account);
  const classes = useStyles();

  const humanLogos = [human_logo_1, human_logo_2, human_logo_3];

  const users = [
    {
      id: userId,
      name: `${lastName} ${firstName}${rightholder ? '' : ' (moi)'}`,
      isMe: true,
      logo: human_logo_1,
    },
    // eslint-disable-next-line
    ...(rightholders || [])
      ?.filter((r) => (isQuote ? !r.resiliated_at : true))
      .map(({ id, last_name, first_name }, index) => ({
        id,
        name: `${last_name} ${first_name}${
          rightholder?.id !== id ? '' : ' (moi)' || ''
        }`,
        logo: humanLogos[(index + 1) % 3],
      })),
  ];

  return (
    <section
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Typography
          variant={'h2'}
          display={'flex'}
          justifyContent={'center'}
        >
          {t('title')}
        </Typography>
        <section style={{ height: '90%' }}>
          <CustomCarousel>
            {users.map((item) => (
              <Button
                key={item.id}
                className={classes.box}
                onClick={() => {
                  handleNext({
                    declarant: userId,
                    ...(item.isMe ? {} : { rightholderId: item.id }),
                  });
                }}
                style={{
                  backgroundColor: props.isActive ? '#C0D8E2' : '',
                }}
              >
                <img
                  src={item.logo}
                  className={classes.img}
                  alt={''}
                />
                <Typography className={classes.typo}>
                  {item.name}
                </Typography>
              </Button>
            ))}
          </CustomCarousel>
        </section>
      </Box>
    </section>
  );
}
