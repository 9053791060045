import { frFR } from '@mui/material/locale';
import { createTheme } from '@mui/material/styles';
import SF from '../static/SF-UI-Display-Regular.woff';

export const yellow = '#f8e873ff';
export const gray1 = '#424242';
export const gray2 = '#86868b';
export const gray3 = '#6E7073';
export const blue = '#52d0c6';
export const purple = '#e80d70';
export const black = '#000000';
export const white = '#ffffff';
export const blue_light = '#eaf1f4';
export const error_color = '#D32F2F';

export const breakpoints = {
  values: {
    xs: 0,
    ss: 400,
    sm: 768,
    md: 1024,
    lg: 1200,
    xl: 1600,
  },
};

const theme = createTheme({ breakpoints });

const isAppleDevice = navigator.vendor
  .toLowerCase()
  .includes('apple');
export const themeCore = {
  breakpoints,
  palette: {
    primary: {
      main: '#000',
      contrastText: '#fff',
    },
    secondary: {
      main: blue,
    },
    purple: {
      main: purple,
      contrastText: '#fff',
    },
    hoverBlue: '#52d0c625',
    button: {
      main: blue,
      contrastText: '#000',
    },
    background: {
      default: '#fff',
    },
    text: {
      main: gray1,
    },
    affiliate: {
      main: '#00891E',
    },
    preaffiliate: {
      main: '#F5C234',
    },
    error: {
      main: '#D32F2F',
    },
  },
  typography: {
    fontFamily: 'SF-UI-Display-Regular',

    h1: {
      zIndex: 100,
      fontWeight: 'bold !important',
      fontSize: '40px !important',
      lineHeight: '48px',
      [theme.breakpoints.down('xl')]: {
        fontSize: '40px !important',
        lineHeight: 'inherit',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '38px !important',
        lineHeight: '38px',
      },
    },
    h2: {
      zIndex: 100,
      fontWeight: 'bold !important',
      fontSize: '32px !important',
      color: gray2,
      // lineHeight:60
      [theme.breakpoints.down('xl')]: {
        fontSize: '22px !important',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '18px !important',
      },
    },
    h3: {
      zIndex: 100,
      fontWeight: 'bold !important',
      color: gray1,
      fontSize: '2.2rem',
      [theme.breakpoints.down('xl')]: {
        fontSize: '1.7rem',
      },
      [theme.breakpoints.down('md')]: {
        fontSize: '1.5rem',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.3rem',
      },
    },
    h4: {
      zIndex: 100,
      color: gray1,
      lineHeight: 1.5,
      fontSize: '1.625rem',
      [theme.breakpoints.down('md')]: {
        fontSize: '1.325rem',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.125rem',
      },
    },
    h5: {
      zIndex: 100,
      fontSize: '16px !important',
      color: gray3,
      textTransform: 'uppercase',
      [theme.breakpoints.down('xl')]: {
        // fontSize: '13px !important'
      },
    },
    h6: {
      zIndex: 100,
      fontSize: '20px !important',
      color: gray1,
      // lineHeight:60
      [theme.breakpoints.down('md')]: {
        fontSize: '14px !important',
      },
    },
  },

  components: {
    MuiYearCalendar: {
      styleOverrides: {
        root: {
          '& .Mui-selected': {
            backgroundColor: `${blue} !important`,
          },
          '& .PrivatePickersYear-root button:hover': {
            backgroundColor: '#52d0c625',
          },
        },
      },
    },
    MuiMonthCalendar: {
      styleOverrides: {
        root: {
          button: {
            color: 'black',
            background: 'none',
            border: 'none',
          },
          '& .MuiTypography-root:hover': {
            backgroundColor: '#52d0c625',
          },
          '& .Mui-selected': {
            backgroundColor: `${blue} !important`,
          },
        },
      },
    },
    MuiDayCalendar: {
      styleOverrides: {
        weekContainer: {
          '& .Mui-selected': {
            backgroundColor: `${blue} !important`,
          },
          '& .MuiPickersDay-root:hover': {
            backgroundColor: '#52d0c625',
          },
        },
      },
    },
    MuiPickersCalendarHeader: {
      styleOverrides: {
        labelContainer: {
          ' .MuiButtonBase-root': {
            display: 'none',
          },
        },
      },
    },
    MuiPickersArrowSwitcher: {
      styleOverrides: {
        root: {
          display: 'none',
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          '& .Mui-selected': {
            backgroundColor: `${blue} !important`,
          },
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        colorPrimary: {
          color: blue,
          '&.Mui-checked': {
            color: blue,
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          whiteSpace: 'pre-wrap',
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          '&:not(.active):hover': {
            backgroundColor: 'transparent !important',
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: '#52d0c625',
          },
          '&.Mui-selected,&.Mui-selected.Mui-focusVisible, &.Mui-selected:hover':
            {
              backgroundColor: '#52d0c690',
            },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          width: 'fit-content',
          margin: 'auto',
          color: gray1,
          fontSize: '16px',
          [theme.breakpoints.down('md')]: {
            fontSize: 12,
          },
          fontWeight: 'initial !important',
          textTransform: 'none',
        },
      },
    },
    MuiStack: {
      styleOverrides: {
        root: {
          width: '100%',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          color: gray1,
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        thumb: {
          backgroundColor: '#fff',
        },
        track: {
          // Controls default (unchecked) color for the track
          opacity: 0.2,
          backgroundColor: '#000',
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        message: {
          color: '#fff',
          '& *': {
            color: '#fff',
          },
        },
        icon: {
          '& *': {
            color: '#fff',
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '1rem',
        },
      },
    },
    MuiDialogContentText: {
      styleOverrides: {
        root: {
          color: gray1,
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          height: '100%',
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        circle: {
          color: purple,
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          '&:after': {
            borderColor: blue,
          },
          '&:hover:not(.Mui-disabled):before': {
            borderColor: blue,
          },
          [theme.breakpoints.down('xl')]: {
            fontSize: '16px !important',
          },
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: `
            @font-face {
                font-family: 'SF-UI-Display-Regular';
                font-style: normal;
                font-display: swap;
                font-weight: ${isAppleDevice ? '900' : '1100'};
                src: local('SF'), local('SF-UI-Display-Regular'), url(${SF}) format('woff2');
                unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;  
             }`,
    },
  },
};

const theme_export = createTheme(themeCore, frFR);

export default theme_export;
