import {
  createApi,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query/react';

// Define a service using a base URL and expected endpoints
export const maintenanceApi = createApi({
  reducerPath: 'maintenanceApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BACKEND_URL}`,
  }),
  endpoints: (builder) => ({
    getActiveMaintenances: builder.query({
      query: () => 'health/maintenances?active',
      transformResponse: (response) => {
        return {
          active: response.metadata.count > 0,
          message:
            response.metadata.count > 0
              ? response.items[0].message
              : '',
        };
      },
    }),
  }),
});

export const { useGetActiveMaintenancesQuery } = maintenanceApi;
