import React, { useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { useDropzone } from "react-dropzone";
import makeStyles from '@mui/styles/makeStyles';

import {blue} from "../../theme";

import iconPDF from "../../static/refund/pdf_icon.svg"
import {Button} from "@mui/material";
import Stack from "@mui/material/Stack";


const useStyles = makeStyles((theme) => ({
  thumbsContainer : {
    overflow: 'auto',
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    border: `${blue} dotted`,
    height: "inherit",
    margin: 10
  },
  
  thumb : {
    position: 'relative',
    borderRadius: 2,
    margin: 10,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: "border-box",
    transition: '.5s ease',
    backfaceVisibility: 'hidden',
    '&:hover img':{
      //image as background
      cursor: 'pointer',
      opacity: 0.3
    },
      //cross overlay
    '&:hover div': {
        opacity: 1
    },
    [theme.breakpoints.down("sm")]: {
      width: 70,
      height: 70,
      margin: 5,
    }
  },
  
  img : {
    opacity: 1,
    display: 'block',
    width: '100%',
    height: '100%',
    
  },

  middle: {
    transition: '.5s ease',
    cursor: 'pointer',
    opacity: 0,
    position: 'absolute',
    top: '50%',
    left: '50%',
    color: 'red',
    transform: 'translate(-50%, -50%)',
    '-ms-transform': 'translate(-50%, -50%)',
    textAlign: 'center',
    zIndex: 100
  }

}));


export default function Dropzone(props) {
  const { t } = useTranslation('account');
  const classes = useStyles();
  const [files, setFiles] = props.files;

  const { getRootProps, getInputProps, open } = useDropzone({
    accept: "image/*, application/pdf",
    noClick: true,
    noKeyboard: true,
    onDrop: acceptedFiles => {
      let previews = acceptedFiles.map(file => 
            Object.assign(file, {
              preview: file.type === 'application/pdf'?iconPDF:URL.createObjectURL(file)
            })
          );
        
      setFiles(files ? files.concat(previews):
                previews
              );
    }
  });

  const removeFile = (file) => {
    let removedArray = files.filter(function(el){
      return el !== file;
    });
    setFiles(removedArray);
  }

  const thumbs = files && files.map(file => (
    <div className={classes.thumb} key={file.name}>
        <img src={file.preview} className={classes.img} alt="" onClick={()=>removeFile(file)}/>
        <div className={classes.middle}>
          <span style={{fontSize: '1.8em'}} onClick={()=>removeFile(file)}>X</span>
        </div>
      <div style={{
        fontSize: '0.9em',
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap"
      }}>{file.name}</div>
    </div>
  ));

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files && files.forEach(file => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  return (
      <div {...getRootProps({ className: "dropzone" })} style={{height: '100%', width: '100%'}}>
        <Stack sx={{ display: 'flex', justifyContent: 'center', marginBottom: {xs: '20px', sm: '40px'}}}>
          <Button variant='contained' style={{fontSize: '18px'}} color="button" onClick={open}>
            {t('dropzone')}
          </Button>
        </Stack>
        <input {...getInputProps()} />
        <div className={classes.thumbsContainer}>{thumbs}</div>
      </div>
  );
}
