import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {Button} from "@mui/material";
import _ from 'lodash';
import CustomDatePicker from "../../molecules/DatePicker";
import {date_yyyy_mm_dd} from "../../../utils/globalFunctions";

const MAX_DATE = new Date()
MAX_DATE.setHours(23,59,59,999);
const MIN_DATE = new Date("1900-01-01")

export default function Step3(props) {
    const { t } = useTranslation('account', { keyPrefix: 'getrefund.step3' });
    const { handleNext, handleBack } = props
    const [dateValue, setDateValue] = useState(new Date());
    return (
        <section style={{ height: '100%', display: 'flex', flexDirection: 'column'}}>
            <Box sx={{ width: '100%', height: { xs: '60%', md: '90%'}, display: "flex", flexDirection: "column"}}>
                <Typography variant={"h2"} display={'flex'} justifyContent={'center'}>{t('title')}</Typography>
                <section style={{ height: '90%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <div style={{maxWidth: '230px'}}>
                        <CustomDatePicker dateValue={dateValue}
                                          onChange={(newValue) => {
                                              setDateValue(newValue);
                                          }}
                                          maxDate={MAX_DATE}
                                          label={t('label')}/>
                    </div>
                </section>
            </Box>
            <div style={{display: 'flex', justifyContent: 'center', marginTop: '20px', marginBottom: '20px'}}>
                <Button variant='contained' style={{fontSize: '18px'}} color="button" onClick={handleBack}>
                    {t('prev')}
                </Button>
                <Button variant='contained' style={{fontSize: '18px', marginLeft: '50px'}} color="button"
                        disabled={!(dateValue instanceof Date && !_.isNaN(dateValue?.valueOf())) || dateValue > MAX_DATE || dateValue < MIN_DATE }
                        onClick={() => { handleNext({date: date_yyyy_mm_dd(dateValue)}) }}>
                    {t('next')}
                </Button>
            </div>
        </section>
    );
}