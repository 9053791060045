import React, {useState, useEffect} from 'react';
import {useNavigate} from "react-router-dom";
import {
    Typography,
    Stack,
    Button,
} from "@mui/material";
import _ from 'lodash';
import HelloSign from 'hellosign-embedded';
import {contractStatus, signContract} from "../../../../utils/axiosRequests";
import Loading from '../../../molecules/Loading'
import useQuery from "../../../../utils/queryFunctions";
import {useTranslation} from "react-i18next";

export default function SignerPage() {

    const {t} = useTranslation('subPro', {keyPrefix: 'bankContact'});
    let navigate = useNavigate();

    let query = useQuery();
    const request_token = query.get("request_token");


    const [errorMessage, setErrorMessage] = useState(null);

    useEffect(() => {
        if (!_.isEmpty(request_token)) {
            console.log(request_token);
        }
    }, [request_token]);


    const [loading, setLoading] = React.useState(false);
    const [isBeingSigned, setIsBeingSigned] = React.useState(false);

    const handleBackEndSign = () => {
        setIsBeingSigned(true)

        var startTime = new Date().getTime();
        const MINUTES_3 = 3 * 60000

        var interval = setInterval(async () => {
            const response = await contractStatus(request_token)
            if (response.data === "signed") {
                clearInterval(interval);

                setIsBeingSigned(false)
                navigate("/login", {replace: true});
            }
            if (new Date().getTime() - startTime > MINUTES_3) {
                clearInterval(interval);
                alert(`La signature du contrat n'a pas aboutie, et son status est ${response.data}`)
                setIsBeingSigned(false)
                return;
            }
        }, 5000);
    }

    const handleSign = () => {
        try {
            setErrorMessage(null)
            setLoading(true);
            signContract(request_token, {'lng':'fr'}).then(
                response => {
                    const hs = new HelloSign(
                        process.env.REACT_APP_HELLO_SIGN_PROD === "true" ?
                            {
                                clientId: process.env.REACT_APP_HELLO_SIGN_CLIENT_ID,
                            }
                            :
                            {
                                clientId: process.env.REACT_APP_HELLO_SIGN_CLIENT_ID,
                                skipDomainVerification: true,
                            });
                    //on modal close, reset states to avoid loop when reopening it
                    hs.on('cancel', () => {
                        console.log("closed");
                    });

                    hs.on('error', () => {
                        console.log("error");
                    });

                    hs.on('sign', () => {
                        console.log("signed");
                        handleBackEndSign()
                        // navigate("/login", { replace: true });
                    });
                    hs.open(response.data);
                    setLoading(false);
                }
            )
                .catch(error => {
                    console.log(error);
                    setLoading(false);
                    setErrorMessage(`Erreur lors de la signature du contrat: ${error}`)
                    throw new Error("Erreur pendant la génération des documents à signer")
                });
        } catch (error) {
            setLoading(false);
            setErrorMessage(`Erreur lors de la signature du contrat: ${error}`)
            console.log(error);
        }
    }

    return (
        <>
            {isBeingSigned ?
                <Stack spacing={2} sx={{maxHeight: '100%', alignItems: 'center', p: 2}}>
                    <Typography variant={"h3"}>
                        Après quelques vérifications, votre compte sera créé.
                        <br/>
                        Dans moins d'une minute, vous serez redirigés vers l'espace de connexion.
                    </Typography>
                    <Loading/>
                </Stack>
                :
                <Stack spacing={2} sx={{maxHeight: '100%', alignItems: 'center', p: 2}}>
                    <Typography variant={"h3"}>
                        Tout est prêt, vous pouvez signer votre contrat Etiq
                    </Typography>
                    <Button
                        onClick={handleSign}
                        disabled={loading}
                        color="button"
                        variant='contained'
                        sx={{margin: 'auto', fontSize: '20px !important', width: 255, height: 60}}
                    >
                        {loading ?
                            <>
                                <div style={{marginRight: '20px'}}><Loading/></div>
                                Creation en cours...</> :
                                t('sub_sign_button')
                        }
                    </Button>
                    {errorMessage?.length ? <Typography style={{ fontSize: '18px', marginTop: '20px', wordBreak: "break-all", maxHeight: '150px', overflow: 'auto', color : '#d32f2f' }}>{errorMessage}</Typography> : <></>}
                </Stack>

            }
        </>
    )
}
