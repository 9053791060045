import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

const fallbackLng = ['fr'];
const availableLanguages = ['en-disabled', 'fr'];

i18n
  .use(HttpApi) // load translations using http (default public/assets/locals/en-disabled/translations)
  .use(LanguageDetector) // detect user language
  .use(initReactI18next) // pass the i18n instance to react-i18next.
  .init({
    partialBundledLanguages: true,
    defaultNS: false,
    ns: [],
    resources: {},
    fallbackLng, // fallback language is french.
    load: 'languageOnly',
    detection: {
      checkWhitelist: true, // options for language detection
      order: ['htmlTag', 'querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'path', 'subdomain'],
    },
    debug: true,

    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },

    whitelist: availableLanguages,

    interpolation: {
      escapeValue: false, // no need for react. it escapes by default
    },
  });

export default i18n;